<template>
  <div class="stepper" role="navigation">
    <div class="stepper-tabs">
      <slot></slot>
    </div>
  </div>
</template>

<script>
/** TODO:
 * - option to collapse title/content when not current
 * - vertical alignment
 * - send content from a stepper item to a output to another display in this main component
 * - allow usage from an object
 */

export default {
  name: "Stepper",
  model: {
    prop: "current",
    event: "change",
  },
  props: {
    /**
     * The current step
     * @type {Number}
     * @default 1
     */
    current: {
      type: Number,
      default: 1,
    },

    /**
     * A list of steps, which will generate stepper-items
     * @type {Object}
     * @default undefined
     */
    steps: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    max() {
      return this.$children.length
    },
  },
  watch: {
    current() {
      this.onChange()
    },
  },
  mounted() {
    this.onChange()
  },
  methods: {
    /**
     * Flip to the previous step
     */
    previous() {
      let step = this.current - 1
      if (step >= 1) {
        this.$emit("change", step)
      }
    },

    /**
     * Flip to the next step
     */
    next() {
      let step = this.current + 1
      if (step <= this.max) {
        this.$emit("change", step)
      }
    },

    /**
     * React to change of `current`
     */
    onChange() {
      // Loop through stepper-items and set their status' and visibility
      const { current } = this
      this.$children.forEach((item, index) => {
        // Get the 1-based step number
        let step = index + 1
        // Less than is `finished`, same is `current`, more is `pending`
        if (step < current) {
          item.setStatus("finished")
        } else if (step > current) {
          item.setStatus("pending")
        } else {
          item.setStatus("current")
        }
      })
    },
  },
}
</script>

<style lang="postcss" scoped>
.stepper {
  .stepper-tabs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .stepper-content {
    min-height: 100px;
  }
}
</style>
