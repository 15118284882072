<template>
  <div
    :id="'#' + id"
    class="toast"
    :class="['toast-' + type]"
    role="alert"
    @click="handleClick"
  >
    <div slot="content">
      <div class="toast-content">
        <h5 class="mb-0">
          {{ title }}
        </h5>
        <p class="mb-0">{{ message }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Toast",
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "secondary",
    },
    title: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
  },
  methods: {
    handleClick() {
      this.$emit("toastClicked")
    },
  },
}
</script>

<style lang="postcss">
.toast {
  width: 100%;
}
</style>
