<template>
  <button
    class="btn"
    :class="[
      isOutline + type,
      btnSize,
      block ? 'btn-block' : 'btn-flex',
      { active: active },
    ]"
    :disabled="disabled"
    :aria-pressed="active"
    role="button"
    :aria-label="label"
    type="button"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <icon
      v-if="icon"
      :icon="icon"
      :class="[{ 'mr-md-2': label }]"
      class="d-md-block"
    ></icon>
    <div v-if="label" class="btn-label d-none d-md-block">
      {{ label }}
    </div>
    <icon
      v-if="additionalIcon"
      :icon="additionalIcon"
      :class="{ 'ml-md-2': label || icon }"
    ></icon>
  </button>
</template>

<script>
import Icon from "./Icon.vue"
export default {
  name: "IconButton",
  components: {
    Icon,
  },
  props: {
    block: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: undefined,
    },
    icon: {
      type: String,
      default: undefined,
    },
    additionalIcon: {
      type: String,
      default: undefined,
    },
    type: {
      type: String,
      default: "primary",
    },
    size: {
      type: String,
      default: "normal",
      validator: function (value) {
        return ["large", "normal", "small"].indexOf(value) > -1
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    menu: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isOutline: function () {
      if (this.outline === true) {
        return "btn-outline-"
      } else {
        return "btn-"
      }
    },
    btnSize: function () {
      if (this.size === "large") {
        return "btn-lg"
      } else if (this.size === "small") {
        return "btn-sm"
      } else {
        return ""
      }
    },
  },
}
</script>

<style lang="postcss" scoped>
/* Normal layout (i.e. not block) needs flex to align properly */
.btn-flex {
  display: inline-flex;
  align-items: center;
}
</style>
