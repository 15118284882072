<template>
  <div class="position-relative">
    <icon-button
      icon="trash-2"
      type="accent"
      label="Delete"
      data-cy="delete-button"
      v-bind="$attrs"
      @click="open = true"
    />
    <div v-if="open" class="confirm card shadow-sm">
      <div class="card-block p-3">
        <h5>Confirm Deletion</h5>
        <div v-if="multiple" class="alert alert-warning my-2 py-2">
          Multiple checks will be deleted!
        </div>
        <p>
          Are you sure you want to delete
          {{ multiple ? "these checks" : "this check" }}?
        </p>
        <div class="d-flex justify-content-end">
          <icon-button
            label="Cancel"
            type="danger"
            icon="x"
            size="small"
            @click="open = false"
          />
          <icon-button
            label="Delete"
            type="danger"
            class="ml-1"
            icon="trash-2"
            outline
            data-cy="confirm-delete"
            size="small"
            @click="emitInput"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import IconButton from "./IconButton"

export default {
  components: {
    IconButton,
  },
  props: {
    gridApi: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      open: false,
    }
  },
  computed: {
    multiple() {
      return this.gridApi.getSelectedRows().length > 1
    },
  },
  methods: {
    emitInput() {
      this.open = false
      this.$emit("input")
    },
  },
}
</script>

<style lang="postcss" scoped>
.confirm {
  top: calc(100% + 5px);
  position: absolute;
  width: 300px;
  z-index: 1;
}
</style>
