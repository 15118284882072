<template>
  <div class="toast-group-wrapper" :style="toastGroupStyles">
    <div v-for="item in list" :key="item.id" class="toast-item">
      <toast
        :id="item.id"
        :type="item.type"
        :content="item.content"
        :title="item.title"
        :message="item.message"
        @toastClicked="destroyItem(item)"
      >
      </toast>
    </div>
  </div>
</template>

<script>
// TODO: explore touchlike interactions

import Vue from "vue"
import Toast from "./Toast.vue"
import uuid from "uuid/v4"
import { toastEventBus } from "@/utils/toastEventBus.js"

// Track whether a notification is idle or destroyed
const STATE = {
  IDLE: "idle",
  DESTROYED: "destroyed",
}
export default {
  name: "ToastGroup",
  components: {
    Toast,
  },
  props: {
    group: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "300px",
    },
    // Options: x: left, center, right; y: top, bottom;
    position: {
      type: String,
      default: "top right",
    },
    // Override the normal animation implementation
    animation: {
      type: Object,
      default: undefined,
    },
    // Length of transition
    speed: {
      type: Number,
      default: 300,
    },
    // Time notification will stay visible (0 means forever)
    duration: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      list: [],
    }
  },
  computed: {
    toastGroupStyles() {
      let { x, y } = this.getPosition(this.position)

      let styles = {
        width: this.width,
        [y]: "0px",
      }

      if (x === "center") {
        styles["left"] = 0
        styles["right"] = 0
        styles["margin"] = "auto"
      } else {
        styles[x] = "0px"
      }

      return styles
    },
  },
  mounted() {
    toastEventBus.$on("add", this.addItem)
  },
  methods: {
    // Add an item to the list
    addItem(item) {
      // Use the group, or make the group '' if none is supplied
      item.group = item.group || ""

      // Don't notify in this group if it's the wrong group
      if (this.group !== item.group) {
        return
      }

      // Clean all items if `clean: true` is passed
      if (item.clean) {
        this.destroyAllItems()
        return
      }

      // Catch an individual toast's duration override
      let duration =
        typeof item.duration === "number" ? item.duration : this.duration

      // Catch an individual toast's speed override
      let speed = typeof item.speed === "number" ? item.speed : this.speed

      const toast = {
        id: item.id || "toast-" + uuid().toString(),
        type: item.type,
        state: STATE.IDLE,
        speed,
        length: duration + 2 * speed,
      }

      // Set the toast content
      if (item.content) {
        toast.content = item.content
      } else {
        toast.title = item.title
        toast.message = item.message
      }

      // Set a timer if duration is set
      if (duration > 0) {
        toast.timer = setTimeout(() => {
          this.destroyItem(toast)
        }, toast.length)
      }

      this.list.push(toast)
    },
    // change the item state and clean it from the array
    destroyItem(item) {
      clearTimeout(item.timer)
      Vue.set(item, "state", STATE.DESTROYED)
      this.cleanItems()
    },
    // destroy all items in the list
    destroyAllItems() {
      this.list.forEach((item) => {
        clearTimeout(item.timer)
        Vue.set(item, "state", STATE.DESTROYED)
      })
      this.cleanItems()
    },
    // remove 'destroyed' items
    cleanItems() {
      this.list = this.list.filter((v) => v.state !== STATE.DESTROYED)
    },
    // Generate styles for the specified ToastGroup position
    getPosition(position) {
      // Make sure the position coordinates given actually work
      const options = {
        x: ["left", "center", "right"],
        y: ["top", "bottom"],
      }

      let x,
        y = null

      // Turn the string into an array
      if (typeof position === "string") {
        position = position.split(/\s+/gi).filter((v) => v)
      }

      // Verify the options
      position.forEach((p) => {
        if (options.x.indexOf(p) !== -1) {
          x = p
        }
        if (options.y.indexOf(p) !== -1) {
          y = p
        }
      })
      return { x, y }
    },
  },
}
</script>

<style lang="postcss" scoped>
.toast-group-wrapper {
  display: block;
  position: fixed;
  z-index: 2000;
  margin-top: 5px;

  .toast-item {
    margin: 0 5px 5px;
  }
}
</style>
