<template>
  <div
    class="
      d-flex
      flex-column
      pt-1
      rounded-top
      border border-secondary border-bottom-0
    "
  >
    <div
      class="
        d-flex
        flex-row
        justify-content-between
        align-items-center
        pb-1
        px-1
      "
    >
      <div class="d-flex">
        <div v-if="showSelectionOptions" class="btn-group" role="group">
          <icon-button
            id="menuToggle"
            type="primary"
            outline
            icon="check-square"
            :label="selectionMode | capitalize"
            :active="multiselect"
            aria-label="Toggle multiselect"
            data-cy="multiselect"
            @click="toggleMultiSelect"
          />
          <button
            id="menuToggle"
            type="primary"
            outline
            class="
              btn btn-flex
              dropdown-toggle
              btn-outline-primary
              border-left-0
              px-2
            "
            aria-haspopup="true"
            aria-expanded="false"
            aria-label="Toggle grid tools menu"
            data-cy="selection-options"
            @click="selectionMenuOpen = !selectionMenuOpen"
          />
          <div
            class="dropdown-menu"
            :class="{ show: selectionMenuOpen }"
            aria-labelledby="menuToggle"
          >
            <a
              class="dropdown-item d-flex flex-row align-items-center"
              type="button"
              data-cy="select-all"
              @click.prevent="selectAll"
            >
              <icon icon="list-checks" fixed-width />
              <span class="ml-2">Select All</span>
            </a>
            <a
              class="dropdown-item d-flex flex-row align-items-center"
              type="button"
              data-cy="select-none"
              @click="selectNone"
            >
              <icon icon="layout-list" fixed-width />
              <span class="ml-2">Select None</span>
            </a>
          </div>
        </div>
      </div>
      <div class="w-25 mr-1">
        <icon-button
          class="w-100 d-flex justify-content-center"
          type="primary"
          label="Advanced Search"
          icon="search"
          :active="expand"
          data-cy="advanced-search-button"
          @click="toggleFiltersCollapse"
        />
      </div>
      <div class="d-flex">
        <slot name="toolbar"></slot>
      </div>
    </div>
    <transition
      enter-active-class="collapsing"
      leave-active-class="collapsing"
      @enter="onEnter"
      @afterEnter="onAfterEnter"
      @leave="onLeave"
      @afterLeave="onAfterLeave"
    >
      <div v-if="expand" class="filter-collapse card m-1">
        <advanced-filter
          :grid-api="gridOptions.api"
          :fields="filterFields"
          @close="expand = false"
        ></advanced-filter>
      </div>
    </transition>
  </div>
</template>

<script>
import AdvancedFilter from "@/components/AdvancedFilter.vue"
import { IconButton, Icon } from "@/components/elements"
export default {
  name: "AdvancedSearchBar",
  components: { AdvancedFilter, IconButton, Icon },
  props: {
    showSelectionOptions: { type: Boolean, default: true },
    filterFields: { type: Array, required: true },
    gridOptions: { type: Object, required: true },
  },
  data() {
    return {
      selectionMenuOpen: false,
      multiselect: false,
      expand: false,
    }
  },
  computed: {
    selectionMode() {
      if (this.multiselect === true) {
        return "multiple"
      } else {
        return "single"
      }
    },
  },
  methods: {
    toggleFiltersCollapse() {
      this.expand = !this.expand
    },
    /** Allow selecting multiple rows with single clicks */
    toggleMultiSelect() {
      this.multiselect = !this.multiselect
      // An exception for changing the grid settings, this shouldn't impact anything via reactivity
      /* eslint-disable vue/no-mutating-props */
      this.gridOptions.rowSelection = this.multiselect ? "multiple" : "single"
      this.gridOptions.rowMultiSelectWithClick = this.multiselect
      /* eslint-enable vue/no-mutating-props */
      this.gridOptions.api.deselectAll()
    },
    selectAll() {
      this.selectionMenuOpen = false
      this.gridOptions.api.selectAllFiltered()
    },
    selectNone() {
      this.selectionMenuOpen = false
      this.gridOptions.api.deselectAll()
    },

    /* Transition animations */
    reflow(el) {
      // requsting an elements offsetHight will trigger a reflow of the element content
      let isElement = el && el.nodeType === Node.ELEMENT_NODE
      return isElement && el.offsetHeight
    },
    onEnter(el) {
      el.style.height = 0
      this.reflow(el)
      el.style.height = el.scrollHeight + "px"
    },
    onAfterEnter(el) {
      el.style.height = null
    },
    onLeave(el) {
      el.style.height = "auto"
      el.style.display = "flex"
      el.style.height = el.getBoundingClientRect().height + "px"
      this.reflow(el)
      el.style.height = 0
    },
    onAfterLeave(el) {
      el.style.height = null
    },
  },
}
</script>
