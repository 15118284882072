<template>
  <component
    :is="iconComponent"
    v-if="size == 'large'"
    role="img"
    class="inline-block fill-current"
    style="height: 5em; width: 5em"
  />
  <component
    :is="iconComponent"
    v-else
    role="img"
    class="inline-block fill-current"
    style="height: 1em; width: 1em"
  />
</template>
<script>
const requireComponents = require.context("../assets/icons/", false, /.svg$/)
const icons = requireComponents.keys().reduce((yielded, fileName) => {
  // file names are like "./icon.svg", so we remove "./" and ".svg"
  const iconName = fileName.slice(2, -4)
  return { [iconName]: requireComponents(fileName), ...yielded }
}, {})

export default {
  props: {
    icon: {
      type: String,
      required: true,
      validator(value) {
        return Object.prototype.hasOwnProperty.call(icons, value)
      },
    },
    size: {
      type: String,
      required: false,
      default: "",
    },
  },
  computed: {
    iconComponent() {
      return icons[this.icon]
    },
  },
}
</script>
