var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group",class:[
    { 'has-success': _vm.success },
    { 'has-warning': _vm.warning },
    { 'has-danger': _vm.danger },
    { row: _vm.inline } ]},[(_vm.isLabel)?_c('label',{class:[{ 'col-sm-2 col-form-label': _vm.inline }],attrs:{"for":_vm.id,"aria-label":_vm.id}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{class:[{ 'col-sm-10': _vm.inline }]},[_c('div',{staticClass:"input-group"},[(_vm.addonVisibility.start)?_c('span',{staticClass:"input-group-prepend"},[_vm._t("addon-start")],2):_vm._e(),_c('input',_vm._b({ref:"input",staticClass:"form-control",class:[
          { 'form-control-sm': _vm.size === 'small' },
          { 'form-control-lg': _vm.size === 'large' },
          { 'form-control-success': _vm.success },
          { 'form-control-warning': _vm.warning },
          { 'form-control-danger': _vm.danger },
          { masked: _vm.masked } ],attrs:{"id":_vm.id,"type":_vm.type,"disabled":_vm.disabled,"aria-describedby":_vm.id + 'Help',"placeholder":_vm.placeholder,"autocomplete":_vm.isAutocomplete},domProps:{"value":_vm.value},on:{"input":_vm.onInput,"change":function($event){return _vm.$emit('change', $event)}}},'input',_vm.$attrs,false)),(_vm.addonVisibility.end)?_c('span',{staticClass:"input-group-append"},[_vm._t("addon-end")],2):_vm._e()]),(_vm.stateEnabled && _vm.success && !_vm.disabled)?_c('div',{staticClass:"form-control-feedback"},[_vm._v(" "+_vm._s(_vm.successMessage)+" ")]):_vm._e(),(_vm.stateEnabled && _vm.warning && !_vm.disabled)?_c('div',{staticClass:"form-control-feedback"},[_vm._v(" "+_vm._s(_vm.warningMessage)+" ")]):_vm._e(),(_vm.stateEnabled && _vm.danger && !_vm.disabled)?_c('div',{staticClass:"form-control-feedback"},[_vm._v(" "+_vm._s(_vm.dangerMessage)+" ")]):_vm._e(),(_vm.isDescription)?_c('small',{staticClass:"text-muted",class:[{ 'form-text': !_vm.inlineDescription }],attrs:{"id":_vm.id + 'Help'}},[_vm._v(" "+_vm._s(_vm.description)+" ")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }