<template>
  <div>
    <label v-if="groupLabel != ''">
      {{ groupLabel }}
    </label>
    <div
      role="radiogroup"
      tabindex="-1"
      class="btn-group btn-toggle-group w-100"
      :aria-label="groupLabel"
    >
      <label
        v-for="option in Object.keys(options)"
        :key="option"
        class="btn btn-outline-primary w-100"
        :class="[{ active: value == option }]"
      >
        <input
          type="radio"
          class="d-none"
          :name="options[option]"
          :value="option"
          :checked="value == option"
          @change="getClicked"
        />
        <span class="mx-auto">
          {{ options[option] }}
        </span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    groupLabel: {
      type: String,
      required: false,
      default: "",
    },
    value: {
      required: true,
      validator: function (value) {
        return ["true", "false", "null"].indexOf(value) > -1
      },
    },
  },
  data() {
    return {
      options: { true: "Yes", false: "No", null: "All" },
    }
  },
  methods: {
    getClicked(e) {
      this.$emit("input", e.target.value)
    },
  },
}
</script>
