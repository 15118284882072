<template>
  <div
    class="stepper-item"
    :class="[
      { 'stepper-item--current': current },
      { 'stepper-item--finished': finished },
      { 'stepper-item--pending': pending },
    ]"
  >
    <div class="stepper-item__header">
      <div class="stepper-item__icon d-flex align-items-center">
        <icon v-if="finishedIcon" :icon="finishedIcon" />
        <icon v-else-if="icon" :icon="icon" />
        <span v-else>{{ label }}</span>
      </div>
      <h6 class="stepper-item__title">
        {{ title }}
      </h6>
      <span class="stepper-item__line"></span>
    </div>
    <small class="stepper-item__description">
      {{ description }}
    </small>
  </div>
</template>

<script>
import Icon from "./Icon.vue"

const statusOptions = ["finished", "pending", "current"]

export default {
  name: "StepperItem",
  components: {
    Icon,
  },
  props: {
    /**
     * The label to display
     */
    label: {
      type: [Number, String],
      default: "",
    },

    /**
     * Specify a default font-awesome icon to display instead of a label
     */
    icon: {
      type: String,
      default: undefined,
    },

    /**
     * The title
     */
    title: {
      type: String,
      default: "",
    },

    /**
     * The decription
     */
    description: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      /**
       * The current status of this step
       */
      status: "",
    }
  },
  computed: {
    /* Just some convenience properties */
    current() {
      return this.status === "current"
    },
    pending() {
      return this.status === "pending"
    },
    finished() {
      return this.status === "finished"
    },
    finishedIcon() {
      if (this.status === "finished") {
        return "check-circle"
      } else {
        return undefined
      }
    },
  },
  methods: {
    setStatus(toSet) {
      if (statusOptions.indexOf(toSet) > -1) {
        this.status = toSet
      } else {
        console.error(
          `Error: Cannot set status: ${toSet}. Must be one of ${statusOptions}!`,
        )
      }
    },
  },
}
</script>

<style lang="postcss" scoped>
.stepper-item {
  flex: auto;
  margin-right: 0.5rem;

  &:last-child {
    margin-right: 0;
    flex: none;

    .stepper-item__line {
      display: none !important;
    }
  }

  /* Current (i.e. active) */
  &.stepper-item--current {
    .stepper-item__icon {
      border-color: var(--primary) !important;
      background-color: var(--primary) !important;
      color: var(--white) !important;
    }

    .stepper-item__title {
      color: var(--black) !important;
    }

    /* .stepper-item__line { */
    /*     background-color: gray !important; */
    /* } */
  }

  /* Finished (i.e. complete) */
  &.stepper-item--finished {
    .stepper-item__icon {
      border-color: var(--primary) !important;
      color: var(--primary) !important;
    }

    .stepper-item__title {
      color: var(--primary) !important;
    }

    .stepper-item__line {
      background-color: var(--primary) !important;
    }
  }

  /* Pending (i.e. upcoming) */
  /* &.stepper-item--pending { */

  /* } */

  .stepper-item__header {
    display: flex;

    .stepper-item__icon {
      border: 1px solid;
      border-color: gray;
      border-radius: 0.75rem;
      color: gray;
      font-size: 0.875rem;
      line-height: 1.375rem;
      padding-left: 0.25rem;
      padding-right: 0.25rem;
      text-align: center;
      display: inline-block;
      height: 1.5rem;
      min-width: 1.5rem;
      position: relative;
    }

    .stepper-item__title {
      margin-left: 0.5rem;
      color: gray;
      margin-bottom: 0px;
      align-self: center;
    }

    .stepper-item__line {
      display: block;
      flex: auto;
      background-color: gray;
      align-self: center;
      height: 1px;
      margin-left: 0.5rem;
    }
  }

  .stepper-item__description {
    display: flex;
    align-self: flex-start;
    margin-left: 2rem;
    color: var(--gray);
  }
}
</style>
