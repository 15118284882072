<template>
  <div class="filters" data-cy="filters">
    <div class="controls">
      <input-box
        v-if="fields.includes('payee_name')"
        v-model="filters.payee_name.filter"
        data-cy="filter-payee_name"
        label="Payee Name"
        placeholder="Wilbur Wildcat"
      >
      </input-box>
      <input-box
        v-if="fields.includes('payee_number')"
        v-model="filters.payee_number.filter"
        data-cy="filter-payee_number"
        label="Payee Number"
        placeholder="XXXXXXXXXX"
      >
      </input-box>
      <single-select
        v-if="fields.includes('check_identifier')"
        v-model="filters.check_identifier.filter"
        data-cy="filter-check_identifier"
        label="Check Identifier"
        :options="options.checkIdentifier"
        placeholder=""
      >
      </single-select>
      <input-box
        v-if="fields.includes('check_number')"
        v-model="filters.check_number.filter"
        v-maska="'#########'"
        data-cy="filter-check_number"
        label="Check Number"
        placeholder="XXXXXXXXX"
      >
      </input-box>
      <single-select
        v-if="fields.includes('instructions')"
        v-model="filters.instructions.filter"
        data-cy="filter-instructions"
        label="Instructions"
        placeholder=""
        :options="options.instructions"
      >
      </single-select>
      <input-box
        v-if="fields.includes('edoc_number')"
        v-model="filters.edoc_number.filter"
        v-maska="'########'"
        data-cy="filter-edoc_number"
        label="Edoc #"
        placeholder="XXXXXXXX"
      >
      </input-box>
      <input-box
        v-if="fields.includes('org_code')"
        v-model="filters.org_code.filter"
        v-maska="'####'"
        data-cy="filter-org_code"
        label="Org Code"
        placeholder="XXXX"
      >
      </input-box>
      <input-box
        v-model="filters.due_date.filter"
        data-cy="filter-due_date"
        type="date"
        label="Due Date"
      ></input-box>
      <input-box
        v-if="fields.includes('contact_name')"
        v-model="filters.contact_name.filter"
        data-cy="filter-contact_name"
        label="Contact Name"
        placeholder="Wilma Wildcat"
      >
      </input-box>
      <input-box
        v-if="fields.includes('contact_number')"
        v-model="filters.contact_number.filter"
        v-maska="['###-###-####', '###-###-#### ext ####']"
        data-cy="filter-contact_number"
        label="Contact Phone"
        placeholder="123-456-7890"
      >
      </input-box>
      <input-box
        v-if="fields.includes('contact_email')"
        v-model="filters.contact_email.filter"
        data-cy="filter-contact_email"
        label="Contact Email"
        placeholder="wilma@email.arizona.edu"
      >
      </input-box>
      <div v-if="fields.includes('contacted')" class="form-group">
        <label class="form-control-label">Contacted</label>
        <radio v-model="filters.contacted.filter" data-cy="filter-contacted" />
      </div>
      <div v-if="fields.includes('hasCheckNumber')" class="form-group">
        <label class="form-control-label">Has Check Number</label>
        <radio v-model="hasCheckNumber" data-cy="filter-hasCheckNumber" />
      </div>
      <input-box
        v-if="fields.includes('created')"
        v-model="filters.created.filter"
        data-cy="filter-created"
        type="date"
        label="Date Created"
      >
      </input-box>
      <input-box
        v-if="fields.includes('user')"
        v-model="filters.user.filter"
        data-cy="filter-user"
        label="Created By"
        placeholder="Username"
      >
      </input-box>
      <div v-if="fields.includes('picked_up')" class="form-group">
        <label class="form-control-label">Picked Up</label>
        <radio v-model="filters.picked_up.filter" data-cy="filter-picked_up" />
      </div>
      <input-box
        v-if="fields.includes('received_by')"
        v-model="filters.received_by.filter"
        data-cy="filter-received_by"
        label="Received By"
        placeholder="Wilma Wildcat"
      >
      </input-box>
      <input-box
        v-if="fields.includes('date_received')"
        v-model="filters.date_received.filter"
        data-cy="filter-date_received"
        type="date"
        label="Date Received"
        placeholder="11-25-2018"
      >
      </input-box>
    </div>
    <div class="d-flex justify-content-end py-2">
      <icon-button
        type="warning"
        outline
        icon="wind"
        label="Clear"
        data-cy="filter-clear"
        @click="resetFilters"
      />
      <icon-button
        class="ml-1"
        icon="x"
        label="Close"
        data-cy="filter-close"
        @click="$emit('close')"
      />
    </div>
  </div>
</template>

<script>
import { toRefs } from "@vue/composition-api"

import { instructionsOptions } from "@/utils/constants"
import useFilter from "@/composables/useFilter"
import {
  InputBox,
  IconButton,
  SingleSelect,
  Radio,
} from "@/components/elements"

export default {
  name: "AdvancedFilter",
  components: {
    InputBox,
    IconButton,
    SingleSelect,
    Radio,
  },
  props: {
    gridApi: { type: Object, required: true },
    fields: { type: Array, required: true },
  },
  setup(props) {
    const { gridApi } = toRefs(props)
    const initialFilters = {
      check_identifier: { type: "contains", filter: "", filterType: "text" },
      check_number: { type: "contains", filter: "", filterType: "text" },
      contact_email: { type: "contains", filter: "", filterType: "text" },
      contact_name: { type: "contains", filter: "", filterType: "text" },
      contact_number: { type: "contains", filter: "", filterType: "text" },
      contacted: { filterType: "text", type: "equals", filter: "null" },
      created: { type: "contains", filter: "", filterType: "text" },
      date_received: { type: "contains", filter: "", filterType: "text" },
      due_date: { filterType: "text", type: "contains", filter: "" },
      edoc_number: { type: "contains", filter: "", filterType: "text" },
      instructions: { type: "contains", filter: "", filterType: "text" },
      org_code: { type: "contains", filter: "", filterType: "text" },
      payee_name: { type: "contains", filter: "", filterType: "text" },
      payee_number: { type: "contains", filter: "", filterType: "text" },
      picked_up: { filterType: "text", type: "equals", filter: "null" },
      received_by: { type: "contains", filter: "", filterType: "text" },
      user: { type: "contains", filter: "", filterType: "text" },
    }
    const { filters, resetFilters } = useFilter({
      gridApi,
      initialFilters,
    })
    return { filters, resetFilters }
  },
  data() {
    return {
      options: {
        instructions: instructionsOptions,
        checkIdentifier: [
          { text: "Accounts Payable", value: "accounts payable" },
          { text: "Payroll", value: "payroll" },
          { text: "Bursar", value: "bursar" },
        ],
      },
    }
  },
  computed: {
    // hasCheckNumber and check_number filters rely on the same filter object,
    // so we create computed proxies to play nicely with v-model
    hasCheckNumber: {
      get() {
        const { type, filter } = this.filters.check_number
        return type == "notNull" ? filter : "null"
      },
      set(value) {
        this.filters.check_number = {
          filterType: "text",
          type: "notNull",
          filter: value,
        }
      },
    },
    checkNumber: {
      get() {
        const { type, filter } = this.filters.check_number
        return type == "contains" ? filter : ""
      },
      set(value) {
        this.filters.check_number = {
          type: "contains",
          filter: value,
          filterType: "text",
        }
      },
    },
  },
}
</script>

<style lang="postcss" scoped>
.filters {
  padding: 0.5em;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.controls {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1400px;

  .form-group {
    flex-basis: 32%;
    flex-grow: 1;
    margin: 0.35rem;
    max-width: 32%;
  }
}
</style>
