import { ref, watch } from "@vue/composition-api"
import { cloneDeep } from "lodash"

export default function useFilter({ gridApi, initialFilters }) {
  const existingFilters = gridApi.value.getFilterModel()
  const filters = ref({ ...cloneDeep(initialFilters), ...existingFilters })

  /**
   * Apply to the grid any filters with values.
   * The string "null" is interpreted as an empty value.
   */
  function applyFilters(filters) {
    const filterModel = {}
    for (const [field, model] of Object.entries(filters)) {
      if (model.filter && model.filter != "null") {
        filterModel[field] = model
      } else if (model.value && model.value != "null") {
        filterModel[field] = model
      }
    }
    if (!gridApi.value) return console.warn("Grid Not Ready for Filtering")
    gridApi.value.setFilterModel(filterModel)
    gridApi.value.onFilterChanged()
  }

  /** Clears filter state and all grid filtering */
  function resetFilters() {
    this.filters = cloneDeep(initialFilters)
    gridApi.value.setFilterModel(null)
    gridApi.value.onFilterChanged()
  }

  watch(filters, applyFilters, { deep: true })

  return { filters, applyFilters, resetFilters }
}
