<template>
  <div class="card">
    <div class="card-block">
      <div class="panel-flex">
        <div
          v-for="col in columns"
          :key="col.headerName"
          class="form-check py-2 w-100"
          @click="toggleColumn(col)"
        >
          <input
            :checked="col.visible"
            type="checkbox"
            :data-cy="`column-${col.colId}`"
            class="form-check-input"
          />
          <label class="form-check-label">{{ col.colDef.headerName }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    columnApi: { required: true, type: Object },
  },
  data() {
    return {
      columns: this.columnApi.getAllColumns(),
    }
  },
  methods: {
    toggleColumn(col) {
      this.columnApi.setColumnVisible(col.colId, !col.visible)
    },
  },
}
</script>

<style lang="postcss" scoped>
.card {
  border-radius: 0;
}

/* Scroll the panel except when in narrow width */
.card-block {
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex: 1 1 100%;
}

.panel-flex {
  display: flex;
  margin: 1em;
  flex: auto;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (max-width: 768px) {
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;

    .item {
      display: block;
      min-width: 20%;
      margin-right: 1em;
    }
  }
}
</style>
