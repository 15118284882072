import { ref, watch } from "@vue/composition-api"
import Vue from "vue"

/**
 * @typedef {Object} UseGridReturn
 * @property {Object} gridApi
 * @property {Object} columnApi
 * @property {Object} gridOptions
 * @property {string} quickFilter
 * @property {boolean} showColumnPanel
 * @property {boolean} selectionExists
 * @property {Function} printGrid
 */

/**
 * Returns a grid options object & helpers for interacting with the grid.
 * @param {Object} gridConfig should include `colDefs` and any option overrides
 * @returns {UseGridReturn}
 */
export default function useGrid(gridConfig) {
  const gridApi = ref(null)
  const columnApi = ref(null)
  const quickFilter = ref("")
  const showColumnPanel = ref(false)
  const selectionExists = ref(false)
  const gridOptions = ref({
    animateRows: true,
    paginationAutoPageSize: true,
    rowSelection: "single",
    rowMultiSelectWithClick: false,
    noRowsOverlayComponent: "noRowsOverlayComponent",

    onGridReady(params) {
      gridApi.value = params.api
      columnApi.value = params.columnApi
    },
    onModelUpdated({ api, columnApi }) {
      columnApi.autoSizeColumns(columnApi.getAllColumns())
      if (!api.getDisplayedRowCount()) {
        api.showNoRowsOverlay()
      } else {
        api.hideOverlay()
      }
    },
    onSelectionChanged({ api }) {
      selectionExists.value = api.getSelectedRows().length > 0
    },

    defaultColDef: {
      filter: "agTextColumnFilter",
      sortable: true,
      resizable: true,
      menuTabs: ["generalMenuTab"],
      filterParams: {
        newRowsAction: "keep",
        textFormatter: (s) => String(s).toLowerCase(), // allows filtering non-string values
        filterOptions: [
          "contains",
          "notEqual",
          "notContains",
          "equals",
          "startsWith",
          "endsWith",
          {
            displayKey: "notNull",
            displayName: "Not Null",
            test: (filterValue, cellValue) => {
              const bool = filterValue == "true"
              return ![null, "", "null"].includes(cellValue) == bool
            },
          },
        ],
      },
    },
    frameworkComponents: {
      // imported, registered to the app in main.js
      checkIdCellRenderer: "CheckIdCellRenderer",
      dateCellRenderer: "DateCellRenderer",
      booleanCellRenderer: "BooleanCellRenderer",
      instructionsCellRenderer: "InstructionsCellRenderer",
      noRowsOverlayComponent: "NoRowsOverlay",
    },
    ...gridConfig,
  })

  watch(quickFilter, (newValue, _oldValue) => {
    gridApi.value.setQuickFilter(newValue)
  })

  /** Styles grid for printing and invokes browser print dialog */
  function printGrid() {
    gridOptions.value.enableFilter = false
    gridApi.value.setDomLayout("print")
    gridApi.value.redrawRows()
    Vue.nextTick(() => window.print())
    Vue.nextTick(() => {
      gridApi.value.setDomLayout("normal")
      gridApi.value.redrawRows()
      gridOptions.value.enableFilter = true
    })
  }

  return {
    gridApi,
    columnApi,
    gridOptions,
    quickFilter,
    showColumnPanel,
    selectionExists,
    printGrid,
  }
}
